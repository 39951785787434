<template>
  <div class="modal" v-show="visible">
    <div class="header">
      <span class="close" @click="$emit('update:visible', false)">&times;</span>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    const onEscape = (e) => {
      if (this.visible === true && e.keyCode === 27) {
        this.$emit("update:visible", false);
      }
    };
    document.addEventListener("keydown", onEscape);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", onEscape);
    });
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  width: 100%;
  height: 100%;
  z-index: 1;

  .header {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;

    .close {
      color: white;
      font-size: 28px;
      font-weight: bold;
    }

    .close:hover,
    .close:focus {
      color: #333;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
</style>
