<template>
  <div class="portfolio">
    <div class="section">
      <content-title name="Mis diseños" />
      <bar />
    </div>
    <div class="photos">
      <masonry />
    </div>
    <div class="footer">
      <footer-content />
    </div>
  </div>
</template>

<script>
import ContentTitle from "@/components/contents/Title";
import Bar from "@/components/contents/Bar.vue";
import FooterContent from "@/components/contents/Footer.vue";
import Masonry from "@/components/Masonry";

export default {
  name: "Portfolio",
  components: {
    ContentTitle,
    Bar,
    FooterContent,
    Masonry,
  },
};
</script>

<style lang="scss" scoped>
.portfolio {
  .section {
    padding: 0 4%;
    width: 100%;
  }

  .photos {
    height: 800px;
    padding: 4%;
    overflow: scroll;
  }

  .footer {
    background-color: #f5f8f9;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
</style>
