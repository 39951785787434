<template>
  <div>
    <div class="masonry">
      <div
        v-for="image in images"
        :key="image"
        :class="image"
        @click="dialogVisible = true"
      >
        <i class="el-icon-view" />
      </div>
    </div>
    <modal :visible.sync="dialogVisible">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="image in images" :key="image" :class="image" />
        <div
          class="swiper-pagination swiper-pagination-white"
          slot="pagination"
        ></div>
        <div
          class="swiper-button-prev swiper-button-white"
          slot="button-prev"
        ></div>
        <div
          class="swiper-button-next swiper-button-white"
          slot="button-next"
        ></div>
      </swiper>
    </modal>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Modal from "@/components/Modal";

export default {
  name: "Portfolio",
  components: {
    Swiper,
    SwiperSlide,
    Modal,
  },
  data() {
    return {
      dialogVisible: false,
      images: [
        "bg1", "bg2", "bg3", "bg4", "bg5", "bg6", "bg7", "bg8", "bg9", "bg10",
        "bg11", "bg12", "bg13", "bg14", "bg15", "bg16", "bg17", "bg18", "bg19", "bg20",
        "bg21", "bg22", "bg23", "bg24", "bg25", "bg26", "bg27", "bg28", "bg29", "bg30",
        "bg31", "bg32", "bg33", "bg34", "bg35", "bg36", "bg37", "bg38", "bg39", "bg40",
        "bg41", "bg42", "bg43", "bg44", "bg45", "bg46", "bg47", "bg48", "bg49", "bg50",
        "bg51", "bg52", "bg53", "bg54", "bg55", "bg56",
      ],
      swiperOption: {
        spaceBetween: 30,
        effect: "fade",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
//Add image classes
@for $i from 1 through 56 {
  .bg#{$i} {
    background-image: url("../assets/Fotos/#{$i}.jpeg");
    background-color: #333333;
  }
}

.swiper {
  height: 280px;
  margin-top: 100px;

  .swiper-slide {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.masonry {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  div {
    cursor: pointer;
    height: 210px;
    border-radius: 5px;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    transition: all 0.3s ease-in;

    i {
      transition: all 0.3s ease-in;
      opacity: 0;
    }
  }

  div:hover {
    opacity: 0.7;
    transform: scale(0.98);

    i {
      opacity: 0.9;
      font-size: 25px;
      background-color: white;
      padding: 15px;
      border-radius: 20px;
    }
  }
}

@media (min-width: 1100px) {
  .masonry {
    div:nth-of-type(1) {
      grid-column: 1 / 3;
    }

    div:nth-of-type(6) {
      grid-column: 3 / 5;
    }

    div:nth-of-type(9) {
      grid-column: 3 / 5;
    }

    grid-template-columns: repeat(4, 1fr);
  }

  .swiper {
    margin-top: 0;
    height: 85vh;
  }
}
</style>
